<template>
  <TosListVuetify
    serviceName="yard"
    entityName="inventoryVisits"
    :pageTitle="title"
    :headers="headers"
    :excludeButtons="{ add: true, exportCsv: excludeButtons.exportCsv?excludeButtons.exportCsv:false }"
    :defaultFilters="defaultFilters"
    :defaultSort="defaultSort"
    :defaulOptions="defaulOptions"
  >
    <template #customFilters="slotProps">
      <v-container fluid class="ma-0 pa-0">
        <v-row align="center" class="ma-0 pa-0">
          <slot name="filterButtons" v-bind:filters="slotProps.data" />
          <v-col cols="2" v-if="!excludeButtons.unitNumber">
            <v-text-field :label="tLabel('Unit number')" v-model="slotProps.data['B.object_codeLike']" />
          </v-col>
          <v-col cols="2" v-if="!excludeButtons.sysTypes">
            <v-select :label="tLabel('SysType')" v-model="slotProps.data.sysTypeIn" :items="sysTypes" multiple />
          </v-col>
          <v-col cols="2" v-if="!excludeButtons.tipoUnit">
            <v-text-field :label="tLabel('Tipo Unit')" v-model="slotProps.data['UV.unit_type_codeLike']" />
          </v-col>
          <v-col cols="2" v-if="!excludeButtons.voyages">
            <v-text-field :label="tLabel('Voyage In/Out')" v-model="slotProps.data.voyagesLike" />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #extraButtons="slotProps">
      <slot name="extraButtons" v-bind:extraButtons="slotProps.data" />
    </template>

    <template v-slot:item.actions="slotProps">
      <div class="text-left">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <slot name="item-menu" v-bind:item="slotProps.data" />
        </v-menu>
      </div>
    </template>

    <template v-slot:item.actions2="slotProps">
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">....</v-btn>
        </template>
        <slot name="item-menu" v-bind:item="slotProps.data" />
      </v-menu>
    </template>

    <template v-slot:item.actions3="slotProps">
      <div class="text-center">
        <slot name="item-menu" v-bind:item="slotProps.data" />
      </div>
    </template>

    <template v-slot:item.sysType="slotProps">
      <v-icon large :title="sysTypesMap[slotProps.value]">{{ iconMap[slotProps.value] }}</v-icon>
    </template>

    <template v-slot:item.objectCode="slotProps">
      <div>
        <span></span>
        <span class="text-h6">{{ slotProps.data.objectCode }} </span>
      </div>
    </template>

    <template v-slot:item.status="slotProps">
      <EnumVisualization :value="slotProps.value" :enums="visitStatus" :enumsMap="visitStatusMap" />
    </template>

    <template v-slot:item.status2="slotProps">
      <span class="bordered" :title="visitStatusMap[slotProps.data.status]">{{ slotProps.data.status }}</span>
    </template>

    <template v-slot:item.positionStatus="slotProps">
      <EnumVisualization :value="slotProps.value" :enums="positionStatus" :enumsMap="positionStatusMap" />
    </template>

    <template v-slot:item.positionStatus2="slotProps">
      <span class="bordered" :title="positionStatusMap[slotProps.data.positionStatus]">{{ slotProps.data.positionStatus }}</span>
    </template>

    <template v-slot:item.customsStatus="slotProps">
      <EnumVisualization :value="slotProps.value" :enums="customsStatus" :enumsMap="customsStatusMap" />
    </template>

    <template v-slot:item.customsStatus2="slotProps">
      <span class="bordered" :title="customsStatusMap[slotProps.data.customsStatus]">{{ slotProps.data.customsStatus }}</span>
    </template>

    <template v-slot:item.fullEmpty="slotProps">
      <EnumVisualization :value="slotProps.value" :enums="fullEmpties" :enumsMap="fullEmptiesMap" />
    </template>

    <template v-slot:item.fullEmpty2="slotProps">
      <span class="bordered" :title="fullEmptiesMap[slotProps.data.fullEmpty]">{{ slotProps.data.fullEmpty }}</span>
    </template>

    <template v-slot:item.dangerous="slotProps">
      <div>
        <v-icon v-if="slotProps.value === true" color="warning" large>mdi-alert</v-icon>
      </div>
    </template>

    <template v-slot:item.hinTransportType="slotProps">
      <div>
        <v-icon large :title="transportTypesMap[slotProps.value]">{{ iconMap[slotProps.value] }}</v-icon>
      </div>
    </template>

    <template v-slot:item.houtTransportType="slotProps">
      <div>
        <v-icon large :title="transportTypesMap[slotProps.value]">{{ iconMap[slotProps.value] }}</v-icon>
      </div>
    </template>

    <template v-slot:item.positionCode="slotProps">
      <div v-if="slotProps.data.positionVisitId">
        <span class="text-subtitle-1"> {{ slotProps.data.positionVisitObjectCode }} </span>
      </div>
      <div v-else>
        <span class="text-subtitle-1"> {{ slotProps.value }} </span>
      </div>
    </template>

    <template v-slot:item.qty="slotProps">
      <div v-if="slotProps.data.sysType === 'GOO' && slotProps.data.countType === 'I'">
        <span class="text-subtitle-1"> {{ slotProps.value }}/{{ slotProps.data.qtyInitial }} </span>
      </div>
      <div v-else>
        <span class="text-subtitle-1"> {{ slotProps.value }}</span>
      </div>
    </template>

    <!-- HANDLING IN DATA -->
    <template v-slot:item.hinOrderCode="slotProps">
      <!-- {{slotProps.data.handlingsIn}} -->
      <div v-for="h in slotProps.data.handlingsIn" v-bind:key="h.id">
        {{ h.orderCode }}
      </div>
    </template>

    <template v-slot:item.hinVoyageCode="slotProps">
      <div v-for="h in slotProps.data.handlingsIn" v-bind:key="h.id">
        {{ h.voyageCode }}
      </div>
    </template>

    <template v-slot:item.hinTransportType="slotProps">
      <div v-for="h in slotProps.data.handlingsIn" v-bind:key="h.id">
        <v-icon large :title="transportTypesMap[h.transportType]">{{ iconMap[h.transportType] }}</v-icon>
      </div>
    </template>

    <template v-slot:item.dataIn="slotProps">
      <div v-for="h in slotProps.data.handlingsIn" v-bind:key="h.id">
        {{ h.execDate | formatDateTimeFromDate }}
      </div>
    </template>

    <template v-slot:item.hinPlannedDate="slotProps">
      <div v-for="h in slotProps.data.handlingsIn" v-bind:key="h.id">
        {{ h.plannedDate | formatDateTimeFromDate }}
      </div>
    </template>

    <!-- HANDLING OUT DATA -->
    <template v-slot:item.houtOrderCode="slotProps">
      <div v-for="h in slotProps.data.handlingsOut" v-bind:key="h.id">
        {{ h.orderCode }}
      </div>
    </template>
    <template v-slot:item.houtVoyageCode="slotProps">
      <!-- {{slotProps.data.handlingsOut}} -->
      <div v-for="h in slotProps.data.handlingsOut" v-bind:key="h.id">
        {{ h.voyageCode }}
      </div>
    </template>

    <template v-slot:item.houtTransportType="slotProps">
      <div v-for="h in slotProps.data.handlingsOut" v-bind:key="h.id">
        <v-icon large :title="transportTypesMap[h.transportType]">{{ iconMap[h.transportType] }}</v-icon>
      </div>
    </template>

    <template v-slot:item.dataOut="slotProps">
      <div v-for="h in slotProps.data.handlingsOut" v-bind:key="h.id">
        {{ h.execDate | formatDateTimeFromDate }}
      </div>
    </template>

    <template v-slot:item.houtPlannedDate="slotProps">
      <div v-for="h in slotProps.data.handlingsOut" v-bind:key="h.id">
        {{ h.plannedDate | formatDateTimeFromDate }}
      </div>
    </template>
  </TosListVuetify>
</template>

<script>
import TosListVuetify from "@/components/anagrafiche/TosListVuetify";
import FormatsMixins from "@/mixins/FormatsMixins";
import YardEnumMixin from "@/mixins/yard/EnumMixin";
import EnumVisualization from "./EnumVisualization.vue";

export default {
  name: "InventoryTable",
  data() {
    return {
      defaulOptions: null
    };
  },
  props: {
    title: { default: "Inventory" },
    headers: { type: Array },
    defaultFilters: { type: Object, default: () => {} },
    defaultSort: {
      type: Object,
      default: () => {
        return { field: "data_in", desc: false };
      },
    },
    excludeButtons: {
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    this.defaulOptions = { page: 1, itemsPerPage: 100, sortBy: [], sortDesc: [ false ], groupBy: [], groupDesc: [], mustSort: false, multiSort: false };
  },
  created() {
    this.$parent.$on("refreshTable", () => {
      this.$emit("refreshTable");
    });
    this.$eventBus.$on("refreshInventoryTable", () => {
      this.$emit("refreshTable");
    });
    this.$parent.initFilters;
  },
  methods: {
    initFilters(filters) {
      if (this.$parent.initFilters) this.$parent.initFilters(filters);
    },
  },
  components: { TosListVuetify, EnumVisualization },
  mixins: [FormatsMixins, YardEnumMixin],
};
</script>

<style scoped>
.bordered {
  min-width: 20px;
  font-weight: bold;
  padding-left: 2px;
  padding-right: 2px;
  border: 1px solid black;
}
</style>


